import 'react-data-grid/lib/styles.css';

import { useManagers } from '@/lib/api';
import DataGrid from 'react-data-grid';

const columns = [
  { key: 'num', name: '#', frozen: true, width: 80 },
  { key: 'constituency', name: 'Constituency' },
  { key: 'phone', name: 'Phone number' },
  { key: 'name', name: 'Manager Name' },
];

const ManagersInfo: React.FC = () => {
  const managers = useManagers();
  const rows = managers.map((a: any, i) => ({ num: i + 1, name: a.firstname + " " + a.lastname, phone: a.phone, constituency: a.constituency.name }))
  return <DataGrid columns={columns} rows={rows} className='mt-10' />
}

export default ManagersInfo