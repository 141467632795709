import { Button } from "@/components/ui/button";
import { ArrowRightIcon, LinkBreak2Icon } from "@radix-ui/react-icons"
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate()
  return <div className="h-[100vh] px-3 w-full flex justify-center items-center flex-col gap-3">
    <LinkBreak2Icon className="scale-150" />
    <h2 className="font-bold text-3xl">Page doesn't exist</h2>
    <Button className="flex items-center" onClick={() => navigate("/")}>Go to main page <ArrowRightIcon /> </Button>
  </div>
}

export default NotFound;