import 'react-data-grid/lib/styles.css';
import { useConstituencyStats, usePollingStationAgents } from "@/lib/api";
import { useAuth } from "@/lib/context";
import { Badge as BadgeIcon, UserCheck, UserMinus, Vote } from "lucide-react";
import MetricCard from "./MetricCard";
import DataGrid from 'react-data-grid';
import { Badge } from '@/components/ui/badge';

const columns = [
  { key: 'num', name: '#', frozen: true, width: 80 },
  { key: 'code', name: 'Polling Station Code' },
  { key: 'station', name: 'Polling Station Name' },
  { key: 'phone', name: 'Phone number' },
  { key: 'agent', name: 'Agent name' },
];

function ConstituencyOverview() {
  const { state } = useAuth()
  const agents = usePollingStationAgents(state.user?.constituency?._id || "")
  const rows = agents.map((a: any, i) => ({ num: i + 1, code: a?.station?.code, station: a?.station?.name, phone: a?.phone, agent: a?.firstname + " " + a?.lastname }))
  const data = useConstituencyStats(state.user?.constituency?._id || "")
  return (
    <div className="w-[90%] xl:w-[70%] max-w-[1336px] mx-auto my-[50px]">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-[50px]">
        <MetricCard
          name="Polling Stations"
          value={data.stations}
          icon={<Vote />}
        />
        <MetricCard
          name="Agents Hired"
          value={data.agents}
          icon={<UserCheck />}
        />
        <MetricCard
          name="Agents Left"
          value={data.stations - data.agents}
          icon={<UserMinus />}
        />
      </div>
      <section className='mt-10'>
        <h4 className="scroll-m-20 text-xl font-semibold tracking-tight flex items-center gap-3">
          <BadgeIcon /> Registered Polling Agents <Badge>{state.user?.constituency?.name}</Badge>
        </h4>
        <DataGrid className='mt-3' columns={columns} rows={rows} />
      </section>
    </div>
  );
}

export default ConstituencyOverview;
