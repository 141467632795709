import React from "react";
import instance from "./instance";

interface CreateManagerData {
  firstname: string;
  lastname: string;
  phone: string;
  password: string;
  constituencyId: string;
  voterId: string;
}

interface CreateAdminData {
  firstname: string;
  lastname: string;
  phone: string;
  password: string;
}

interface CreatePollingAgent extends CreateManagerData {
  stationId: string;
}

const api = {
  login(
    phone: string,
    password: string,
    accountType: "ADMIN" | "MANAGER" = "ADMIN"
  ) {
    return instance.post(`/users/login?accountType=${accountType}`, {
      phone,
      password,
    });
  },

  createManager(data: CreateManagerData) {
    return instance.post(`/users/manager`, data);
  },

  getConstituencies(query: string) {
    return instance.get(`/atoms/constituencies?query=${query}`);
  },

  getConstituencyStats(constituencyId: string) {
    return instance.get(
      `/atoms/constituencies/stats?constituencyId=${constituencyId}`
    );
  },

  createPollingAgent(data: CreatePollingAgent) {
    return instance.post(`/users/agent`, data);
  },

  getPollingStations(query: string, constituencyId: string) {
    return instance.get(
      `/atoms/stations?constituencyId=${constituencyId}&query=${query}`
    );
  },

  getPollingStationAgents(constituencyId: string) {
    return instance.get(`/users/agent?constituencyId=${constituencyId}`);
  },

  createAdmin(data: CreateAdminData) {
    return instance.post("/users/admin", data);
  },

  getAdmins() {
    return instance.get("/users/admin");
  },

  getManagers() {
    return instance.get("/users/manager");
  },

  getAgents() {
    return instance.get("/users/agent");
  },

  getStats() {
    return instance.get("/atoms/stats");
  },
};

export const useConstituencies = (query: string) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    api.getConstituencies(query).then((resp) => {
      setData(resp.data.results);
    });
  }, [query]);
  return data;
};

export const useConstituencyStats = (constituencyId: string) => {
  const [data, setData] = React.useState({
    stations: 0,
    agents: 0,
    managers: 0,
  });
  React.useEffect(() => {
    api.getConstituencyStats(constituencyId).then((resp) => {
      setData(resp.data);
    });
  }, [constituencyId]);
  return data;
};

export const usePollingStations = (query: string, constituencyId: string) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    api.getPollingStations(query, constituencyId).then((resp) => {
      setData(resp.data.results);
    });
  }, [query, constituencyId]);
  return data;
};

export const usePollingStationAgents = (constituencyId: string) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    api.getPollingStationAgents(constituencyId).then((resp) => {
      setData(resp.data);
    });
  }, [constituencyId]);
  return data;
};

export const useAdmins = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    api.getAdmins().then((resp) => {
      setData(resp.data);
    });
  }, []);
  return data;
};

export const useManagers = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    api.getManagers().then((resp) => {
      setData(resp.data);
    });
  }, []);
  return data;
};

export const useAgents = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    api.getAgents().then((resp) => {
      setData(resp.data);
    });
  }, []);
  return data;
};

export const useStats = () => {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    api.getStats().then((resp) => {
      setData(resp.data);
    });
  }, []);
  return data as any;
};

export default api;
