import { ArrowRightIcon } from "@radix-ui/react-icons";
import React, { FC } from "react";
interface MetricCardProps {
  name: string;
  value: number | string | React.ReactNode;
  icon: React.ReactNode;
  linkText?: string;
  onClick?: () => void
}
export const MetricCard: FC<MetricCardProps> = ({
  name,
  value,
  icon,
  linkText,
  onClick
}) => {
  return (
    <div className="border border-gray-200 rounded-xl px-8 py-5 shadow cursor-pointer hover:border-teal-600" onClick={onClick}>
      <div className=" flex justify-between">
        <p className="font-xl mb-4 font-semibold ">{name}</p>
        <span className="text-5xl text-gray-500">{icon}</span>
      </div>
      <p className=" text-4xl">{value}</p>
      {linkText ?
        <p className=" text-sm mt-3 text-teal-600 font-semibold flex items-center gap-2">{linkText} <ArrowRightIcon /></p> : null
      }
    </div>
  );
};

export default MetricCard;
