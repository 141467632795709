import { Button } from "@/components/ui/button"
import {
  Menubar,
  MenubarMenu,
  MenubarTrigger
} from "@/components/ui/menubar"
import { useAuth } from "@/lib/context"
import { ExitIcon, MaskOnIcon } from "@radix-ui/react-icons"
import { Tabs, TabsList } from "@radix-ui/react-tabs"
import React from "react"
import { NavLink, Outlet } from "react-router-dom"

interface AdminLayoutProps {
  navs: Array<{ name: string, path: string }>
}

const BaseLayout: React.FC<AdminLayoutProps> = ({ navs }) => {
  const { state, dispatch } = useAuth();

  const logoutHandler = () => {
    dispatch({ type: "logout" })
  }
  return <section>
    <header className="flex justify-between md:px-8 px-2 py-4 bg-gray-800 text-white items-center">
      <div className="flex items-center gap-3">
        <MaskOnIcon className="size-10" />
        <div>
          <div className="capitalise font-bold text-lg">{state.user?.firstname} {state.user?.lastname}</div>
          <div className="capitalises">{state.user?.constituency?.name || "Main Office"}</div>
        </div>
      </div>
      <div>
        <Button variant="destructive" size="sm" onClick={logoutHandler}><ExitIcon /> Logout</Button>
      </div>
    </header>
    <Tabs className="mt-4">
      <TabsList className="flex justify-center">
        <Menubar>
          {
            navs.map(nav => {
              return <NavLink
                to={nav.path}
                key={nav.path}
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <MenubarMenu key={nav.path}>
                    <MenubarTrigger className={isActive ? "!bg-gray-800 !text-white" : ""}>{nav.name}</MenubarTrigger>
                  </MenubarMenu>
                )}
              </NavLink>
            })
          }
        </Menubar>
      </TabsList>
      <section className="w-full px-3">
        <Outlet />
      </section>
    </Tabs>
  </section >
}

export default BaseLayout