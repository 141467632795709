import * as React from 'react';
import { getAuthState, LoggedInUser } from './utils';

export type AuthState = {
  init: boolean
  user: LoggedInUser | undefined;
  access_token: string | undefined;
};

type Action =
  | { type: "loaded" }
  | { type: "init", user: LoggedInUser, access_token: string }
  | { type: "login", user: LoggedInUser, access_token: string }
  | { type: "logout" };

type ContextType = {
  state: AuthState;
  dispatch: React.Dispatch<Action>;
};

const MainContext = React.createContext<ContextType | undefined>(undefined);

function authReducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
    case 'loaded': {
      return { ...state, init: true }
    }
    case 'init': {
      return { ...state, user: action.user, access_token: action.access_token }
    }
    case 'login': {
      return { ...state, user: action.user, access_token: action.access_token }
    }
    case 'logout': {
      localStorage.removeItem("pars-state");
      return { ...state, user: undefined, access_token: undefined }
    }
    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

type AuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(authReducer, { user: undefined, access_token: undefined, init: false });
  React.useEffect(() => {
    const auth_state = getAuthState();
    if (auth_state?.access_token && auth_state.user) dispatch({ type: "init", user: auth_state.user, access_token: auth_state.access_token })
    dispatch({ type: "loaded" })
  }, [])
  React.useEffect(() => {
    localStorage.setItem("pars-state", JSON.stringify(state))
  }, [state])
  const value = React.useMemo(() => ({ state, dispatch }), [state]);
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

function useAuth() {
  const context = React.useContext(MainContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };