import axios from "axios";
import { getAuthState } from "./utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
});

instance.interceptors.request.use(function (config) {
  const state = getAuthState();
  if (state?.access_token)
    config.headers.Authorization = `Bearer ${state.access_token}`;
  return config;
});

export default instance;
