import { useAuth } from "@/lib/context";
import { PropsWithChildren } from "react";
import { Link, Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  role?: "MANAGER" | "ADMIN"
}

const ProtectedRoute: React.FC<PropsWithChildren<ProtectedRouteProps>> = ({ children, role }) => {
  const { state } = useAuth();
  if (!state.init) return <div>redirecting... <Link to="/" className="text-blue-600 underline" >click here to redirect</Link></div>
  if (!state.user) return <Navigate to="/" />
  if (role && state.user.role !== role) return <Navigate to="/" />
  return <>{children}</>
}

export default ProtectedRoute