import { Badge } from '@/components/ui/badge';
import { DotFilledIcon, PlusIcon } from '@radix-ui/react-icons';
import { Loader2, Vote } from "lucide-react";
import 'react-data-grid/lib/styles.css';
import MetricCard from "./MetricCard";
import api, { useStats } from '@/lib/api';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/components/ui/input';
import Swal from 'sweetalert2';
import React from 'react';

const formSchema = z.object({
  firstname: z.string().min(2).max(50),
  lastname: z.string().min(2).max(50),
  phone: z.string().refine((value) => {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(value);
  }, {
    message: "Invalid phone number format. Expected format is 0244564335",
  }),
  password: z.string()
})

function ConstituencyOverview() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })
  const navigate = useNavigate();

  const stats = useStats()

  const [open, setOpen] = React.useState(false)
  function onSubmit(values: z.infer<typeof formSchema>) {
    // return new Promise(resolve => {
    //   setTimeout(resolve, 1000 * 60);
    // })
    return api.createAdmin(values).then(resp => {
      setOpen(false)
      Swal.fire({ title: "Created", confirmButtonColor: "black", text: "Office Personnel created successfully", icon: "success" })
      form.reset()
    }).catch(e => {
      setOpen(false)
      Swal.fire({ title: "Creation Error", icon: "error", text: "There was an error creating office personnel", confirmButtonColor: "black" })
    }).finally(() => {
    })
  }
  return (
    <div className="w-[90%] xl:w-[70%] max-w-[1336px] mx-auto my-[50px]">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-[50px]">
        <MetricCard
          name="Constituencies"
          value={<div className='flex items-center gap-2'>
            <div className='flex items-center flex-col'>
              <div className='text-2xl font-bold'>{Number(stats?.managedConstituencies).toLocaleString()}</div>
              <Badge variant="outline" className='text-sm font-normal'>assigned</Badge>
            </div>
            <div className='text-sm'><DotFilledIcon /></div>
            <div className='flex items-center flex-col'>
              <div className='text-2xl font-bold'>{Number(stats?.constituencies).toLocaleString()}</div>
              <Badge variant="outline" className='text-sm font-normal'>total</Badge>
            </div>
          </div>
          }
          icon={<Vote />}
          linkText='view managers'
          onClick={() => navigate("/office/information/managers")}
        />
        <MetricCard
          name="Polling Agents"
          value={<div className='flex items-center gap-2'>
            <div className='flex items-center flex-col'>
              <div className='text-2xl font-bold'>{Number(stats?.hiredAgents).toLocaleString()}</div>
              <Badge variant="outline" className='text-sm font-normal'>hired</Badge>
            </div>
            <div className='text-sm'><DotFilledIcon /></div>
            <div className='flex items-center flex-col'>
              <div className='text-2xl font-bold'>{Number(stats?.agents).toLocaleString()}</div>
              <Badge variant="outline" className='text-sm font-normal'>total</Badge>
            </div>
          </div>
          }
          icon={<Vote />}
          linkText='view polling agents'
          onClick={() => navigate("/office/information/agents")}
        />
      </div>
      <div className='mt-6'>
        <div className='font-bold my-2 text-2xl'>Perform Actions</div>
        <div className='flex gap-3'>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
              <Button variant="outline" type='button'> <PlusIcon /> Add Office Personnel</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Main Office Personnel</DialogTitle>
                <DialogDescription>
                  You're about to add someone who will have access to the entire system. <span className='text-red-500'>This person should be from main office only.</span>
                </DialogDescription>
              </DialogHeader>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
                  <FormField
                    control={form.control}
                    name="firstname"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Firstname</FormLabel>
                        <FormControl>
                          <Input placeholder="eg. Samson" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastname"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Lastname</FormLabel>
                        <FormControl>
                          <Input placeholder="eg. Awuku" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone Number</FormLabel>
                        <FormControl>
                          <Input placeholder="eg. 0543444444" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" className='w-full' size="lg" disabled={form.formState.isSubmitting}>{form.formState.isSubmitting ? <><Loader2 className="mr-2 h-4 w-4 animate-spin" /> submitting </> : "Add Office Personnel"}</Button>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
          <Button variant="outline" type='button' onClick={() => navigate("/office/add-manager")}> <PlusIcon /> Add Constituency Manager</Button>
        </div>
      </div>
      {/* <div className='mt-6'>
        <div className='font-bold my-2 text-2xl'>Feeds</div>
      </div> */}
    </div>
  );
}

export default ConstituencyOverview;
