import 'react-data-grid/lib/styles.css';

import clsx from 'clsx';
import { NavLink, Outlet } from 'react-router-dom';


const MainInfoTables: React.FC = () => {
  return <section className='mt-10'>
    <div className='flex gap-1 border-b-2'>
      <NavLink
        to="admins"
      >
        {({ isActive, isPending, isTransitioning }) => (
          <div className={clsx("px-4 py-2 text-gray-700 font-semibold", { "border-b-4 border-blue-800 !text-blue-800": isActive })}>Main Office Staff</div>
        )}
      </NavLink>
      <NavLink
        to="managers"
      >
        {({ isActive, isPending, isTransitioning }) => (
          <div className={clsx("px-4 py-2 text-gray-700 font-semibold", { "border-b-4 border-blue-800 !text-blue-800": isActive })}>Constituency Managers</div>
        )}
      </NavLink>

      <NavLink
        to="agents"
      >
        {({ isActive, isPending, isTransitioning }) => (
          <div className={clsx("px-4 py-2 text-gray-700 font-semibold", { "border-b-4 border-blue-800 !text-blue-800": isActive })}>Polling station agents</div>
        )}
      </NavLink>
    </div>
    <Outlet />
  </section>
}

export default MainInfoTables