import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import api from "@/lib/api"
import { useAuth } from "@/lib/context"
import { Label } from "@radix-ui/react-label"
import React from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"


const LoginPage: React.FC = () => {
  const { state } = useAuth();
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const adminLoginHandler = handleSubmit((data) => {
    return api.login(data.phone, data.password).then((resp) => {
      dispatch({ type: "login", user: resp.data.user, access_token: resp.data.access_token })
      navigate("/office")
    }).catch(e => console.error(e))
  })
  const managerLoginHandler = handleSubmit((data) => {
    return api.login(data.phone, data.password, "MANAGER").then(resp => {
      dispatch({ type: "login", user: resp.data.user, access_token: resp.data.access_token })
      navigate("/constituency")
    }).catch(e => console.error(e))
  })

  React.useEffect(() => {
    if (state.init && state.access_token && state.user) {
      if (state.user.role === "MANAGER") navigate("/constituency")
      else navigate("/office")
    }
  }, [state.init, state.access_token, state.user, navigate])
  return <section className="h-[100vh] px-3 w-full flex justify-center items-center">
    <Tabs defaultValue="account" className="w-[400px]">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="account">Office Personnel</TabsTrigger>
        <TabsTrigger value="password">Constituency Manager</TabsTrigger>
      </TabsList>
      <TabsContent value="account">
        <Card>
          <form onSubmit={adminLoginHandler}>
            <CardHeader>
              <CardTitle>Main Office</CardTitle>
              <CardDescription>
                Manage stuff from the main office.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-1">
                <Label htmlFor="name">phone number</Label>
                <Input {...register("phone")} defaultValue="" />
              </div>
              <div className="space-y-1">
                <Label htmlFor="username">password</Label>
                <Input type="password" {...register("password")} />
              </div>
            </CardContent>
            <CardFooter>
              <Button className="w-full" type="submit">Login</Button>
            </CardFooter>
          </form>
        </Card>
      </TabsContent>
      <TabsContent value="password">
        <Card>
          <form onSubmit={managerLoginHandler}>
            <CardHeader>
              <CardTitle>Constituency</CardTitle>
              <CardDescription>
                Manage polling agent recruitment.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-1">
                <Label htmlFor="current">phone number</Label>
                <Input {...register("phone")} type="text" />
              </div>
              <div className="space-y-1">
                <Label htmlFor="new">password</Label>
                <Input {...register("password")} type="password" />
              </div>
            </CardContent>
            <CardFooter>
              <Button className="w-full" type="submit">Login</Button>
            </CardFooter>
          </form>
        </Card>
      </TabsContent>
    </Tabs>
  </section>

}

export default LoginPage