import 'react-data-grid/lib/styles.css';

import { useAdmins } from '@/lib/api';
import DataGrid from 'react-data-grid';

const columns = [
  { key: 'num', name: '#', frozen: true, width: 80 },
  { key: 'name', name: 'Person Name' },
  { key: 'phone', name: 'Phone number' },
  { key: 'by', name: 'Created by' },
];

const AdminsInfo: React.FC = () => {
  const admins = useAdmins();
  const rows = admins.map((a: any, i) => ({ num: i + 1, name: a.firstname + " " + a.lastname, phone: a.phone, by: a.owner ? a.owner.firstname + " " + a.owner.lastname : "unknown" }))
  return <DataGrid columns={columns} rows={rows} className='mt-10' />
}

export default AdminsInfo