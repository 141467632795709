import { clsx, type ClassValue } from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";
import { AuthState } from "./context";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface LoggedInUser {
  firstname: string;
  lastname: string;
  role: "MANAGER" | "ADMIN";
  constituency?: {
    name: string;
    _id: string;
  };
}

export function getAuthState() {
  try {
    const raw = localStorage.getItem("pars-state");
    if (!raw) return;
    const user = JSON.parse(raw) as AuthState;
    return user;
  } catch {}
}
