import { createBrowserRouter, Navigate } from "react-router-dom";
import BaseLayout from "./pages/AdminLayout";
import LoginPage from "./pages/LoginPage";
import AddManager from "./widgets/AddManager";
import MainInfoTables from "./widgets/MainInfoTables";
import MainOverview from "./widgets/MainOverview";
import ProtectedRoute from "./widgets/ProtectedRoute";
import ConstituencyOverview from "./widgets/ConstituencyOverview";
import AddPollingAgent from "./widgets/AddPollingAgent";
import NotFound from "./widgets/NotFound";
import AdminsInfo from "./widgets/AdminsInfo";
import ManagersInfo from "./widgets/ManagersInfo";
import AgentsInfo from "./widgets/AgentsInfo";

const router = createBrowserRouter([
  { path: "/", element: <LoginPage /> },
  {
    path: "/office",
    element: <ProtectedRoute role="ADMIN">
      <BaseLayout navs={[{ name: "Overview", path: "overview" }, { name: "Information Tables", path: "information" }, { name: "Add Constituency Manager", path: "add-manager" }]} />
    </ProtectedRoute>,
    children: [
      {
        path: "",
        element: <Navigate to="overview" replace />,
      },
      {
        path: "overview",
        element: <MainOverview />,
      },
      {
        path: "information",
        element: <MainInfoTables />,
        children: [
          {
            path: "",
            element: <Navigate to="admins" replace />
          },
          {
            path: "admins",
            element: <AdminsInfo />
          },
          {
            path: "managers",
            element: <ManagersInfo />
          },
          {
            path: "agents",
            element: <AgentsInfo />
          },
        ]
      },
      {
        path: "add-manager",
        element: <AddManager />
      }
    ]
  },
  {
    path: "/constituency",
    element: <ProtectedRoute role="MANAGER">
      <BaseLayout navs={[{ name: "Overview", path: "overview" }, { name: "Add Polling Agent", path: "add-agent" }]} />
    </ProtectedRoute>,
    children: [
      {
        path: "",
        element: <Navigate to="overview" replace />,
      },
      {
        path: "overview",
        element: <ConstituencyOverview />
      },
      {
        path: "add-agent",
        element: <AddPollingAgent />
      }
    ]
  },
  { path: "*", element: <NotFound /> },
]);

export default router;
