import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { Check, ChevronsUpDown } from "lucide-react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import Swal from 'sweetalert2'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import api, { useConstituencies } from "@/lib/api"
import { cn } from "@/lib/utils"
import React from "react"

const formSchema = z.object({
  firstname: z.string().min(2).max(50),
  lastname: z.string().min(2).max(50),
  phone: z.string().refine((value) => {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(value);
  }, {
    message: "Invalid phone number format. Expected format is 0244564335",
  }),
  voterId: z.string().min(10).max(10),
  constituencyId: z.string()
})

const AddManager: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState("")
  const [query, setQuery] = React.useState("")
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const v = React.useMemo(() => query, [query])
  const constituencies = useConstituencies(v);
  const frameworks = React.useMemo(() =>
    constituencies.map((c: any) => ({ value: c._id, label: c.name })),
    [constituencies]
  )


  function onSubmit(values: z.infer<typeof formSchema>) {
    api.createManager({ ...values, password: "default" }).then(resp => {
      Swal.fire({ title: "Created", confirmButtonColor: "black", text: "Constituency Manager created successfully", icon: "success" })
      form.reset()
    }).catch(e => {
      Swal.fire({ title: "Creation Error", icon: "error", text: "There was an error creating constituency manager", confirmButtonColor: "black" })
    }).finally(() => {
    })
  }
  return <section className="w-[400px] mx-auto mt-10 mb-60">
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="firstname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Firstname</FormLabel>
              <FormControl>
                <Input placeholder="eg. Samson" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Lastname</FormLabel>
              <FormControl>
                <Input placeholder="eg. Awuku" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone number</FormLabel>
              <FormControl>
                <Input placeholder="eg. 0245555555" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="voterId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Voter ID</FormLabel>
              <FormControl>
                <Input placeholder="eg. 3234534545" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="constituencyId" render={({ field }) => (
            <FormItem>
              <FormLabel>Constituency</FormLabel>
              <FormControl>
                <div>
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between"
                      >
                        {value
                          ? frameworks.find((framework) => framework.value === value)?.label
                          : "Select Constituency..."}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[400px] p-0">
                      <Command shouldFilter={false}>
                        <CommandInput placeholder="Search Constituence..." value={query} onValueChange={(v) => setQuery(v)} />
                        <CommandList>
                          <CommandEmpty>No Constituency found.</CommandEmpty>
                          <CommandGroup>
                            {frameworks.map((framework) => (
                              <CommandItem
                                key={framework.value}
                                value={framework.value}
                                onSelect={(currentValue: string) => {
                                  setValue(currentValue === value ? "" : currentValue)
                                  form.setValue("constituencyId", currentValue);
                                  setOpen(false)
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    value === framework.value ? "opacity-100" : "opacity-0"
                                  )}
                                />
                                {framework.label}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )} />

        <Button type="submit">Add Constituency Manager</Button>
      </form>
    </Form>
  </section>
}

export default AddManager